import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CookiesProvider } from "react-cookie";
import Routes from "./routes/Routes";
import { InternetStatusProvider } from "./providers/internetStatusProvider";
import { MongodbProvider } from "./providers/mongodbProvider";
import { CurrentUserProvider } from "./providers/currentUserProvider";
import { ErrorBoundary } from "./components/ErrorBoundary";

// Themes

// For Saas import Saas.scss
import "./assets/scss/Saas.scss";

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';

/* Create a client */
const queryClient = new QueryClient({
  /* This is not the default behaviour.
  But we must use this option otherwise react-query won't
  fetch data when the user is offline.

  https://tanstack.com/query/v4/docs/react/guides/network-mode
  */
  defaultOptions: {
    queries: {
      networkMode: "always",
    },
    mutations: {
      networkMode: "always",
    },
  },
});

/**
 * Main app component
 */
const App = () => {
  return (
    <>
      <ErrorBoundary>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <InternetStatusProvider>
              <MongodbProvider>
                <CurrentUserProvider>
                  <Routes></Routes>
                </CurrentUserProvider>
              </MongodbProvider>
            </InternetStatusProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </CookiesProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
