// @flow
import { AuthActionTypes } from "./constants";

type AuthAction = { type: string, payload: {} | string };

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: any
): AuthAction => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthAction => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (username: string, password: string): AuthAction => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { username, password },
});

export const logoutUser = (): AuthAction => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  firstName: string,
  lastName: string,
  email: string,
  username: string,
  password: string,
  password_confirmation: string,
  referral: string,
  user_agent: string,
  email_notify_on_misc_ih_updates: boolean
): AuthAction => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: {
    first_name: firstName,
    last_name: lastName,
    email: email,
    username: username,
    password: password,
    password_confirmation: password_confirmation,
    referral: referral,
    user_agent: user_agent,
    terms_of_service: true,
    email_notify_on_misc_ih_updates: email_notify_on_misc_ih_updates,
  },
});

export const forgotPassword = (username: string): AuthAction => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { username },
});

export const forgotPasswordChange = (username: string): AuthAction => ({
  type: AuthActionTypes.FORGOT_PASSWORD_CHANGE,
  payload: { username },
});

export const resetAuth = (): AuthAction => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
