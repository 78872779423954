import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import conf from "../../config";
import ExternalLink from "../../components/common/externalLink";

function ErrorEndOfLife() {
  return (
    <>
      <Helmet>
        <title>BoothMate | Error</title>
      </Helmet>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Card>
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h4 className="text-uppercase text-danger mt-3">
                      UPDATE YOUR APPLICATION
                    </h4>
                    <p className="text-muted mt-3">
                      Your version of BoothMate is no longer supported.
                      <br />
                      <br />
                      Please download and install the most recent version from{" "}
                      <ExternalLink
                        url={`${conf.CONFIG_APP_URL}/download`}
                        className="alert-link"
                      >
                        this page
                      </ExternalLink>
                      <br />
                      <br />
                      You can also email our customer support
                      <br />
                      with further questions or comments.
                      <br />
                      <b>{conf.CONFIG_SUPPORT_EMAIL}</b>.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <footer className="footer footer-alt">
        &copy; 2013 - {new Date().getFullYear()} BoothMate by TapirLabs{" "}
        <small>
          (v{conf.CONFIG_APP_VERSION} / {conf.CONFIG_APP_BUILD})
        </small>
        <br />
        <small>Formerly known as Interpreters' Help.</small>
      </footer>
    </>
  );
}

export default ErrorEndOfLife;
