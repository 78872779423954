import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { appBuild } from "../../services/os";
import conf from "../../config";

function ErrorFallback() {
  return (
    <>
      <Helmet>
        <title>BoothMate | Error</title>
      </Helmet>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Card>
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h4 className="text-uppercase text-danger mt-3">
                      We ran into an error
                    </h4>
                    <p className="text-muted mt-3">
                      Error details have been logged for our engineers
                      <br /> so they can fix it.
                      <br />
                      <br />
                      You can try refreshing the page.
                      <br />
                      <br />
                      You can also email our customer support
                      <br />
                      with further questions or comments.
                      <br />
                      <b>{conf.CONFIG_SUPPORT_EMAIL}</b>.
                    </p>
                    {conf.CONFIG_APP_BUILD !== appBuild.WEB ? (
                      <p>
                        <b>Please quit the app and restart it.</b>
                      </p>
                    ) : (
                      <a href="/" className="btn btn-info mt-3">
                        <i className="mdi mdi-reply"></i> Return Home
                      </a>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <footer className="footer footer-alt">
        &copy; 2013 - {new Date().getFullYear()} BoothMate by TapirLabs{" "}
        <small>
          (v{conf.CONFIG_APP_VERSION} / {conf.CONFIG_APP_BUILD})
        </small>
        <br />
        <small>Formerly known as Interpreters' Help.</small>
      </footer>
    </>
  );
}

export default ErrorFallback;
