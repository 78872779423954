// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { logger } from "../../services/logger";

import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  forgotPasswordConfirm,
} from "../../helpers/";

import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
  try {
    const response = yield call(loginApi, { username, password });
    const user = response.data;
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error) {
    logger.error(error);
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
  }
}

/**
 * Logout the user
 */
function* logout() {
  try {
    yield call(logoutApi);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error) {
    logger.error(error);
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: {
    first_name,
    last_name,
    email,
    username,
    password,
    password_confirmation,
    referral,
    user_agent,
    terms_of_service,
    email_notify_on_misc_ih_updates,
  },
}) {
  try {
    const response = yield call(signupApi, {
      first_name,
      last_name,
      email,
      username,
      password,
      password_confirmation,
      referral,
      user_agent,
      terms_of_service,
      email_notify_on_misc_ih_updates,
    });
    const user = response.data;
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error) {
    logger.error(error);
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
  }
}

function* forgotPassword({ payload: { username } }) {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error) {
    logger.error(error);
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

function* forgotPasswordChange({ payload: { data } }) {
  try {
    const response = yield call(forgotPasswordConfirm, data);
    yield put(
      authApiResponseSuccess(
        AuthActionTypes.FORGOT_PASSWORD_CHANGE,
        response.data
      )
    );
  } catch (error) {
    logger.error(error);
    yield put(
      authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error)
    );
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

function* authSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchForgotPasswordChange),
  ]);
}

export default authSaga;
