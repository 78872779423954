export const queryKeys = Object.freeze({
  APP_VERSION: "app_version", // "x.x.x" | "mac" | "windows" | "linux"
  APP_VERSION_MOST_RECENT: "app_version_most_recent", // "node"
  USER: "user", // slug
  GRIDSETTING: "gridsetting", // user_id
  USER_TAGS: "user_tags", // user_id
  USER_PUBLIC_GLOSSARIES: "user_public_glossaries", // user_id
  USER_SETTING: "user_setting", // user_id
  USER_CONFIRM_TOKEN: "user_confirm_token", // token
  ENTRY: "entry", // entryId
  FOLLOWERS: "followers", // slug
  FOLLOWEES: "followees", // slug
  FOLLOWINGS: "followings", // slug
  COLLEAGUES: "colleagues", // slug
  USERS_SEARCH: "users_search", // query
  COLLEAGUE_REQUESTS_PENDING: "colleague_request_pending", // user_id
  SUBSCRIPTION: "subscription", // user_id
  // SUBSCRIPTION_PLAN: "subscription_plan", // user_id
  QUOTA_ENTRIES: "quota_entries", // user_id
  // QUOTA_CONSUMPTIONS: "quota_consumptions", // user_id
  GLOSSARY: "glossary", // pathname
  GLOSSARIES: "glossaries", // user_id
  GLOSSARIES_NAME: "glossaries_name", // user_id
  GLOSSARIES_FOR_SELECT: "glossaries_for_select", // user_id
  GLOSSARIES_SHARED_WITH_ME: "glossaries_shared_with_me", // user_id
  GLOSSARIES_LAST_UPDATED: "glossaries_last_updated", // user_id
  GLOSSARIES_LAST_OPENED: "glossaries_last_opened", // user_id
  GLOSSARIES_USER_IS_A_COLLABORATOR_OF: "glossaries_user_is_a_collaborator_of", // user_id
  GLOSSARIES_USER_HAS_ACCESS_TO: "glossaries_user_has_access_to", //user_id
  DATABASE_COLUMNS: "database_columns", // user_id / groupId
  USER_LIGHT_GLOSSARIES: "user_light_glossaries", // user_id
  GROUP_LIGHT_GLOSSARIES: "group_light_glossaries", // groupId
  JOB: "job", // pathname
  JOBS: "jobs", // user_id
  JOBS_NAME: "jobs_name", // user_id
  JOBS_FINISHED: "jobs_finished", // user_id
  JOBS_UPCOMING: "jobs_upcoming", // user_id
  JOBS_LAST_UPDATED: "jobs_last_updated", // user_id
  JOBS_LAST_OPENED: "jobs_last_opened", // user_id
  JOBS_USER_IS_A_COLLABORATOR_OF: "jobs_user_is_a_collaborator_of", // user_id
  JOBS_USER_HAS_ACCESS_TO: "jobq_user_has_access_to", //user_id
  LIGHT_JOBS: "light_jobs", // user_id
  CLIENT: "client", // pathname
  CLIENTS: "clients", // user_id
  CLIENTS_NAME: "clients_name", // user_id
  CLIENT_JOB_HISTORY: "client_job_history", // pathname
  LIGHT_CLIENTS: "light_clients", // user_id
  DECK: "deck", // pathname
  DECKS: "decks", // user_id
  DECKS_LAST_UPDATED: "decks_last_updated", // user_id
  DECKS_NAME: "decks_name", // user_id
  DECKS_ENTRIES: "decks_entries", // deckId
  DECK_ENTRY_REVIEW_SESSIONS: "deck_entry_review_sessions", // deckId
  DECK_ENTRIES_DUE_TO_REVIEW: "deck_entries_due_to_review", // deckId
  DECK_ENTRIES_TO_REVIEW_NEXT: "deck_entries_to_review_next", // deckId
  DECK_ENTRIES_REVIEWED: "deck_entries_reviewed", // deckId
  DECK_ENTRY_REVIEW_DUE_COUNT: "deck_entry_review_due_count", // deckId
  ENTRY_REVIEW_SESSION: "entry_review_session", // pathname
  ENTRY_REVIEW_SESSION_ENTRIES: "entry_review_session_entries", // entryReviewSessionId
  LIGHT_DECKS: "light_decks", // user_id
  EVENTS: "events", // user_id
  UPLOADS: "uploads", // user_id
  GROUP: "group", // pathname
  GROUPS: "groups", // user_id
  GROUPS_USER_CAN_COPY: "groups_user_can_copy", // user_id
  GROUP_GLOSSARIES_USER_HAS_ACCESS_TO: "group_glossaries_user_has_access_to", //group_id_user_id
  GROUP_DELETEABLE_STATUS: "group_deleteable_status", // group_id
  GROUPS_WHERE_MEMBER_IS_A_SUPER_ADMIN: "groups_where_member_is_a_super_admin", // userId
  SUBSCRIPTION_GROUPS: "subscription_groups", // user_id
  CUSTOM_ABILITIES_ON_GROUP_GLOSSARIES: "custom_abilities_on_glossaries", // group_id_user_id
  TRANSCRIPTION: "transcription", // pathname
  TRANSCRIPTIONS: "transcriptions", // user_id
  TRANSCRIPT_CONTENT: "transcript_content", // transcodingId
  CUSTOMER: "customer", // userId or groupId
  CUSTOMERS: "customer", // userId or groupId
  CUSTOMER_INVOICES: "customer_invoices", // customerId
  SUBSCRIPTION_REQUEST: "subscription_request", // pathname
  SUBSCRIPTION_REQUESTS: "subscription_requests", // user_id
  ORGANIZATIONS: "organizations", // kind
});
