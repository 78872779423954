import { BrowserRouter } from "react-router-dom";
import { AllRoutes } from "./index";
import ScrollToTop from "../components/ScrollToTop";

const WebRoutes = () => {
  return (
    <BrowserRouter
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <ScrollToTop />
      <AllRoutes />
    </BrowserRouter>
  );
};

export default WebRoutes;
