import { realmApp } from "./realm";
import { ObjectId } from "bson";
import { logger } from "../logger";
import { mdbErrors } from "../mongodb";

// TODO: We should put all this in a realmUser object
// and include the schema

export function dbGetUserByLegacyId(legacyId) {
  logger.info("In dbGetUserByLegacyId");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.getUserByLegacyId(legacyId);
}

export function dbGetUserBySlug(slug) {
  logger.info("In dbGetUserBySlug");
  logger.info("slug", slug);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!slug) {
    logger.error(mdbErrors.UNDEFINED_USER_SLUG);
    return Promise.reject(mdbErrors.UNDEFINED_USER_SLUG);
  }

  return realm.getUserBySlug(slug);
}

export function dbIncrementUserConsumptionBytesUsageBy(userId, by) {
  logger.info("In dbIncrementUserConsumptionBytesUsageBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserConsumptionBytesUsageBy(userId, by);
}

export function dbIncrementUserProcessingBytesUsageBy(userId, by) {
  logger.info("In dbIncrementUserProcessingBytesUsageBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserProcessingBytesUsageBy(userId, by);
}

export function dbIncrementUserAcquiredStorageBytesBy(userId, by) {
  logger.info("In dbIncrementUserAcquiredStorageBytesBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserAcquiredStorageBytesBy(userId, by);
}

export function dbIncrementUserAcquiredProcessingBytesBy(userId, by) {
  logger.info("In dbIncrementUserAcquiredProcessingBytesBy");
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.incrementUserAcquiredProcessingBytesBy(userId, by);
}

export function dbAddFromGroupSubscriptionToUser(userId, subscription) {
  logger.info("In dbAddFromGroupSubscriptionToUser");
  logger.info("userId", userId);
  logger.info("subscription", subscription);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER);
    return Promise.reject(mdbErrors.UNDEFINED_USER);
  }

  return realm.addFromGroupSubscriptionToUser(ObjectId(userId), subscription);
}

export function dbRemoveUserFromGroupSubscriptionForGroup(userId, groupId) {
  logger.info("In dbRemoveUserFromGroupSubscriptionForGroup");
  logger.info("userId", userId);
  logger.info("groupId", groupId);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  return realm.removeUserFromGroupSubscriptionForGroup(
    ObjectId(userId),
    groupId?.toString()
  );
}

export function dbUpdateUser(creator, userId, updatedFields) {
  logger.info("In dbUpdateUser");
  logger.info("creator", creator);
  logger.info("userId", userId);
  logger.info("updatedFields", updatedFields);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  updatedFields["updatedAt"] = new Date();
  return realm.updateUser(creator, userId, updatedFields);
}

export function dbGetUsersByIds(ids) {
  logger.info("In dbGetUsersByIds");
  logger.info("ids", ids);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!ids) {
    logger.error(mdbErrors.UNDEFINED_IDS);
    return Promise.reject(mdbErrors.UNDEFINED_IDS);
  }

  const oids = ids.map((id) => new ObjectId(id));
  return realm.getUsersByIds(oids);
}

export function dbAddAColleagueToUser(userId, colleague) {
  logger.info("In dbAddAColleagueToUser");
  logger.info("userId", userId);
  logger.info("colleague", colleague);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER_ID);
    return Promise.reject(mdbErrors.UNDEFINED_USER_ID);
  }

  if (!colleague) {
    logger.error(mdbErrors.UNDEFINED_COLLEAGUE);
    return Promise.reject(mdbErrors.UNDEFINED_COLLEAGUE);
  }

  colleague["createdAt"] = new Date();
  const oUserId = typeof userId === "string" ? ObjectId(userId) : userId;
  return realm.addAColleagueToUser(oUserId, colleague);
}

export function dbAddAFollowerToUser(userId, follower) {
  logger.info("In dbAddAFollowerToUser");
  logger.info("userId", userId);
  logger.info("follower", follower);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER_ID);
    return Promise.reject(mdbErrors.UNDEFINED_USER_ID);
  }

  if (!follower) {
    logger.error(mdbErrors.UNDEFINED_FOLLOWER);
    return Promise.reject(mdbErrors.UNDEFINED_FOLLOWER);
  }

  follower["createdAt"] = new Date();
  const oUserId = typeof userId === "string" ? ObjectId(userId) : userId;
  return realm.addAFollowerToUser(oUserId, follower);
}

export function dbAddAFollowingToUser(userId, following) {
  logger.info("In dbAddAFolloweeToUser");
  logger.info("userId", userId);
  logger.info("following", following);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER_ID);
    return Promise.reject(mdbErrors.UNDEFINED_USER_ID);
  }

  if (!following) {
    logger.error(mdbErrors.UNDEFINED_FOLLOWING);
    return Promise.reject(mdbErrors.UNDEFINED_FOLLOWING);
  }

  following["createdAt"] = new Date();
  const oUserId = typeof userId === "string" ? ObjectId(userId) : userId;
  return realm.addAFollowerToUser(oUserId, following);
}

export function dbUpdateUserColleagueStatus(userId, colleagueUserId, status) {
  logger.info("In dbUpdateUserColleagueStatus");
  logger.info("userId", userId);
  logger.info("colleagueUserId", colleagueUserId);
  logger.info("status", status);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER_ID);
    return Promise.reject(mdbErrors.UNDEFINED_USER_ID);
  }

  if (!colleagueUserId) {
    logger.error(mdbErrors.UNDEFINED_COLLEAGUE_USERID);
    return Promise.reject(mdbErrors.UNDEFINED_COLLEAGUE_USERID);
  }

  if (!status) {
    logger.error(mdbErrors.UNDEFINED_STATUS);
    return Promise.reject(mdbErrors.UNDEFINED_STATUS);
  }

  const oUserId = typeof userId === "string" ? ObjectId(userId) : userId;
  return realm.updateUserColleagueStatus(oUserId, colleagueUserId, status);
}

export function dbRemoveColleagueFromUser(userId, colleague) {
  logger.info("In dbRemoveColleagueToUser");
  logger.info("userId", userId);
  logger.info("colleague", colleague);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER_ID);
    return Promise.reject(mdbErrors.UNDEFINED_USER_ID);
  }

  if (!colleague) {
    logger.error(mdbErrors.UNDEFINED_COLLEAGUE);
    return Promise.reject(mdbErrors.UNDEFINED_COLLEAGUE);
  }

  const oUserId = typeof userId === "string" ? ObjectId(userId) : userId;
  return realm.removeColleagueFromUser(oUserId, colleague);
}

export function dbRemoveFollowerFromUser(userId, follower) {
  logger.info("In dbRemoveFollowerFromUser");
  logger.info("userId", userId);
  logger.info("follower", follower);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER_ID);
    return Promise.reject(mdbErrors.UNDEFINED_USER_ID);
  }

  if (!follower) {
    logger.error(mdbErrors.UNDEFINED_FOLLOWER);
    return Promise.reject(mdbErrors.UNDEFINED_FOLLOWER);
  }

  const oUserId = typeof userId === "string" ? ObjectId(userId) : userId;
  return realm.removeFollowerFromUser(oUserId, follower);
}

export function dbRemoveFollowingFromUser(userId, following) {
  logger.info("In dbRemoveFollowingFromUser");
  logger.info("userId", userId);
  logger.info("following", following);
  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!userId) {
    logger.error(mdbErrors.UNDEFINED_USER_ID);
    return Promise.reject(mdbErrors.UNDEFINED_USER_ID);
  }

  if (!following) {
    logger.error(mdbErrors.UNDEFINED_FOLLOWING);
    return Promise.reject(mdbErrors.UNDEFINED_FOLLOWING);
  }

  const oUserId = typeof userId === "string" ? ObjectId(userId) : userId;
  return realm.removeFollowingFromUser(oUserId, following);
}

export function dbGetUserByUsernameOrEmail(query) {
  logger.info("In dbGetUserByUsernameOrEmail");
  logger.info("query", query);

  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!query) {
    logger.error(mdbErrors.UNDEFINED_QUERY);
    return Promise.reject(mdbErrors.UNDEFINED_QUERY);
  }

  const strippedQuery = query?.trim();
  return realm.getUserByUsernameOrEmail(strippedQuery);
}

export function dbGetUsersByUsernameOrEmail(query) {
  logger.info("In dbGetUsersByUsernameOrEmail");
  logger.info("query", query);

  const realm = realmApp?.currentUser?.functions;
  if (!realm) return Promise.reject(mdbErrors.REALM_UNDEFINED);

  if (!query) {
    logger.error(mdbErrors.UNDEFINED_QUERY);
    return Promise.reject(mdbErrors.UNDEFINED_QUERY);
  }

  const strippedQuery = query?.trim();
  return realm.getUsersByUsernameOrEmail(strippedQuery);
}
